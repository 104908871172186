import { Button, makeStyles, Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { useModal } from "mui-modal-provider";
import { ShowFnOutput } from "mui-modal-provider/dist/types";
import Image from "next/image";
import { createElement, MouseEvent, useState, VFC } from "react";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { YoutubeVideoPlayerModal } from "../YoutubeVideoPlayerModal";
import { QuestStepProps } from "./quests.types";
import { QuestVideoPlayerModalProps } from "./QuestVideoPlayerModal";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: 340,
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.primary.main,
    },
    textAndBtns: {},
    description: {
      padding: theme.spacing(1.5, 2, 2, 2),
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    btnCont: {
      padding: theme.spacing(0, 0, 1, 0.5),
    },
    btn: {
      color: theme.palette.common.black,
    },
    imgWrapper: {
      width: "100%",
      "& > span": {
        position: "unset !important",
      },
      "& > span > img": {
        objectFit: "contain",
        width: "100% !important",
        position: "relative !important",
        height: "unset !important",
      },
    },
    notReady: {
      "& > span > img": {
        height: "0 !important",
      },
    },
    svg: {},
    skeleton: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    noDisplay: {
      display: "none",
    },
  }),
  {
    classNamePrefix: "QuestGenericPopover",
  }
);

export type QuestGenericPopoverJSSClassKey = keyof ReturnType<typeof useStyles>;

export type QuestGenericPopoverProps = QuestStepProps & {
  classes?: Partial<ClassNameMap<QuestGenericPopoverJSSClassKey>>;
  className?: string;
};

export const QuestGenericPopover: VFC<QuestGenericPopoverProps> = ({
  className,
  classes: extClasses,
  stepConfig,
  onClose,
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const { showModal } = useModal();

  const [imageReady, setImageReady] = useState(false);

  const handleClose = useCallbackSafeRef(() => {
    onClose?.();
  });

  const preventDefault = useCallbackSafeRef((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  });

  const handleVideoClose = useCallbackSafeRef((modal: ShowFnOutput<QuestVideoPlayerModalProps>) => {
    modal.hide();
  });

  const handleWatchVideo = useCallbackSafeRef(() => {
    const modal = showModal(YoutubeVideoPlayerModal, {
      onClose: () => handleVideoClose(modal),
      videoLink: stepConfig.videoLink,
      videoOptions: stepConfig.videoOptions,
    });
  });

  return (
    <div className={clsx(classes.root, className)} onMouseOver={preventDefault} onClick={preventDefault}>
      {(() => {
        if (!!stepConfig.popoverConfig?.img) {
          return (
            <>
              <Skeleton
                className={clsx(classes.skeleton, { [classes.noDisplay]: imageReady })}
                variant="rect"
                height={200}
                width="100%"
              />
              <div className={clsx(classes.imgWrapper, { [classes.notReady]: !imageReady })}>
                <Image layout="fill" src={stepConfig.popoverConfig.img} onLoadingComplete={() => setImageReady(true)} />
              </div>
            </>
          );
        } else if (!!stepConfig.popoverConfig?.svg) {
          return createElement(stepConfig.popoverConfig.svg, { className: classes.svg });
        }
      })()}
      <div className={classes.textAndBtns}>
        <Typography variant="body1" className={classes.description}>
          {stepConfig.popoverConfig?.description}
        </Typography>
        <div className={classes.btnCont}>
          <Button className={classes.btn} onClick={handleClose}>
            Close
          </Button>
          {!!stepConfig.videoLink && (
            <Button className={classes.btn} onClick={handleWatchVideo}>
              Watch Video
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
